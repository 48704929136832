import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import CodeRedImg from "../../../shared/img/coderedimg.png";
import React from "react";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import {useMediaQuery} from "react-responsive";
import CodeRedMobile from "../../../shared/img/codered_mobile.png";

const CodeRed = () => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});
    if (!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Breadcrumbs site="Code Red"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Code Red</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Rejestr przychodzących zapytań o dane pozycjonujące telefonu. Rejestr posiada 16 pozycji
                                gdzie będzie wyświetlona data i godzina otrzymania zapytania z sieci GSM. Takie
                                wymuszenie podania pozycji może oznaczać również, że telefon został przejęty przez
                                podstawiony nadajnik BTS (tak zwany stingray) i w tym przypadku również zobaczysz takie
                                próby jako zapisane logowania w CODE RED.</p><p> W ramach funkcji stealth mode zastosowaliśmy
                                jeszcze inne techniki detekcji podstawionych stacji typu stingray/IMSI catcher/GSM
                                interceptor. Wszystkie nasze rozwiązania dostarczają raporty o próbach nieuprawnionego
                                logowania widoczne jako logi w CODE RED</p>
                        </S.TitleContainer>
                        <S.Phone width={"450px"} MarginRight={"200px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami CodeRed"} src={CodeRedImg}/>
                    </S.PhoneContainer>
                </Grow>

            </>
        );
    } else {
        return (
            <>
                <Header/>
                <Breadcrumbs site="Code Red"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Code Red</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Funkcja umożliwiająca kasowanie pamięci flash telefonu.</p>
                        </S.TitleContainer>
                        <S.Phone width={"550px"} MarginRight={"200px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami CodeRed"} src={CodeRedMobile}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        )
    }
}
export default CodeRed;