import React from 'react';
import Header from "../Header/Header";
import * as S from "../../shared/style";
import Footer from "../Footer/Footer";
import HomeAnim from "../../shared/animations/animajca-koder29.06OK.mp4";
import Grow from "@material-ui/core/Grow";
import {Link} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
const Homepage = () => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});

    if (!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Grow in={3300}>
                    <S.Container direction>

                        <S.Column width={"50%"}>
                            <S.Title>The most secure phone that uses AES256 encryption in the GSM network.</S.Title>
                            <S.Box>
                                <S.BoxTitle>Czym jest k0der?</S.BoxTitle>
                                <S.BoxText>
                                    K0der jest telefonem typu smartfon, który: </S.BoxText>
                                <S.BoxText>1. Posiada udoskonalenia znacznie podnoszące bezpieczeństwo
                                    komunikacji</S.BoxText>
                                <S.BoxText>2. Ma niestandardowy i uszczelniony kod modemu</S.BoxText>
                                <S.BoxText>3. Jest odporny na podsłuch kanałów rozmownych</S.BoxText>
                                <S.BoxText>4. Jest zaprojektowany dla ludzi, którzy cenią sobie prywatność</S.BoxText>
                                <S.BoxText>transmitowanych przez sieć GSM danych</S.BoxText>
                                <S.BoxText margin={"25px"}>Chcesz poznać aplikacje k0dera? Zaloguj się lub pobierz
                                    krótką
                                    instruckję.</S.BoxText>
                            </S.Box>
                            <Link to={"logowanie"}><S.Button>Zaloguj się</S.Button></Link>
                            <Link to={"instrukcja"}><S.Button background_color={"rgba(255, 255, 255, 0)"}>Zobacz
                                instrukcję</S.Button></Link>
                            <S.BoxText margin={"25px"}>Masz pytania? Skontaktuj się: </S.BoxText>
                            <S.BoxText color={"#00A8FF"}>info@k0der.com</S.BoxText>
                        </S.Column>
                        <S.Column MarginTop={"-100px"} width={"50%"}>
                            <video autoPlay muted loop>
                                <source src={HomeAnim}
                                        type="video/mp4"/>
                            </video>
                        </S.Column>
                    </S.Container>
                </Grow>

                <Footer/>
            </>
        );
    }else{
        return(
            <>
                <Header/>
                <Grow in={3300}>
                    <S.Container >

                        <S.Column width={"100%"}>
                            <S.Title>The most secure phone that uses AES256 encryption in the GSM network.</S.Title>

                            <S.Box>
                                <S.BoxTitle>Czym jest k0der?</S.BoxTitle>
                                <S.BoxText>
                                    K0der jest telefonem typu smartfon, który: </S.BoxText>
                                <S.BoxText>1. Posiada udoskonalenia znacznie podnoszące bezpieczeństwo
                                    komunikacji</S.BoxText>
                                <S.BoxText>2. Ma niestandardowy i uszczelniony kod modemu</S.BoxText>
                                <S.BoxText>3. Jest odporny na podsłuch kanałów rozmownych</S.BoxText>
                                <S.BoxText>4. Jest zaprojektowany dla ludzi, którzy cenią sobie prywatność</S.BoxText>
                                <S.BoxText>transmitowanych przez sieć GSM danych</S.BoxText>
                                <S.BoxText margin={"25px"}>Chcesz poznać aplikacje k0dera? Zaloguj się lub pobierz
                                    krótką
                                    instruckję.</S.BoxText>
                            </S.Box>
                            <Link to={"logowanie"}><S.Button>Zaloguj się</S.Button></Link>
                            <Link to={"instrukcja"}><S.Button background_color={"rgba(255, 255, 255, 0)"}>Zobacz
                                instrukcję</S.Button></Link>
                            <S.BoxText margin={"25px"}>Masz pytania? Skontaktuj się: </S.BoxText>
                            <S.BoxText color={"#00A8FF"}><S.mailLink href="mailto:info@k0der.com">info@k0der.com</S.mailLink></S.BoxText>
                        </S.Column>

                        <S.Column center MarginBottom={"150px"} MarginTop={"100px"} width={"100%"}>
                            <video autoPlay muted loop>
                                <source src={HomeAnim}
                                        type="video/mp4"/>
                            </video>
                        </S.Column>

                    </S.Container>
                </Grow>

                <Footer/>
            </>
        )
    }
}

export default Homepage;