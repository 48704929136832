import React  from 'react';
import whiteLogo from "../../shared/img/k0der-logo-biale@1X.png";
import * as S from "../../shared/style/index";
import {Link} from "react-router-dom";
const Header = () =>{
    return(
      <>
          <S.Header>
              <Link to="/"><S.Logo alt={"Logo strony, kłódka 100% security k0der"} src={whiteLogo}/></Link>
              <S.Right></S.Right><S.LinkStyled href="http://koder.computersoft.net.pl/">PL</S.LinkStyled><S.LinkStyled Margin href="http://en.koder.computersoft.net.pl/">EN</S.LinkStyled>
          </S.Header>
      </>
    );
}
export default Header;