import React, {useState} from 'react';
import whiteLogo from "../../shared/img/k0der-logo-biale@1X.png";
import * as S from "../../shared/style/index";
import {LogoContainer} from "../../shared/style/index";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Grow from "@material-ui/core/Grow";
import axios from "axios";
const Auth = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    let history = useHistory();
    const [error, setError] = useState("");

    const onSubmit = data => {
        axios.post('/auth/login', {
            email: data.email,
            password: data.password,
            mode: "cookie"
        },{
            'Content-Type': 'application/json',
        })
            .then(function (response) {
                if(response.status == 200)
                history.push("/main");

            })
            .catch(function (error) {
                setError("Wprowadzone dane są nieprawidłowe!");
            });
    }

    return (
        <>
            <Grow in={3300}>
            <S.AuthContainer>
                <S.AuthBox onSubmit={handleSubmit(onSubmit)}>
                    <S.AuthText>Zaloguj się, by zobaczyć pełny opis aplikacji</S.AuthText>
                    <S.LogoContainer>
                    <S.AuthLogo margin={"0"} src={whiteLogo}/>
                    </S.LogoContainer>

                    <S.InputContainer>
                    <S.Label for="email">E-mail</S.Label>
                    <S.Input {...register("email")} id="email" placeholder={"E-mail"}/>
                    </S.InputContainer>
                    <S.InputContainer>
                    <S.Label htmlFor="password">Hasło</S.Label>
                    <S.Input {...register("password")} id="password" placeholder={"Hasło"}/>

                        <S.Label color={"#E85858"}>{error}</S.Label>
                    </S.InputContainer>
                    <S.Button top={"40px"} width={"100%"}>Zaloguj się</S.Button>
                </S.AuthBox>



            </S.AuthContainer>
            </Grow>
        </>
    );
}

export default Auth;