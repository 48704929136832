import React from 'react';
import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import StealthModeImg from "../../../shared/img/stealthmode.png";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import StealthModeMobile from "../../../shared/img/stealth_modeMobile.png";
import {useMediaQuery} from "react-responsive";

const StealthMode = () =>{
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    if(!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Breadcrumbs site="Stealth Mode"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Stealth Mode</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Funkcja wyłączenia wysyłania raportów pomiarowych do sieci gsm.</p>
                            <p>Dzięki temu telefon jest dużo trudniejszy do zlokalizowania. Nie można go namierzyć co do
                                metra, wiadomo tylko że pozostaje w zasięgu danego nadajnika BTS.</p></S.TitleContainer>
                        <S.Phone MarginRight={"250px"} width={"600px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami QuickContacts"} src={StealthModeImg}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        );
    }else{
        return(
            <>
                <>
                    <Header/>
                    <Breadcrumbs site="Stealth Mode"></Breadcrumbs>
                    <Grow in={3300}>
                        <S.PhoneContainer>
                            <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Stealth Mode</S.Title></S.TitleContainer>
                            <S.TitleContainer>
                                <p>Funkcja wyłączenia wysyłania raportów pomiarowych do sieci gsm.</p>
                                <p>Dzięki temu telefon jest dużo trudniejszy do zlokalizowania. Nie można go namierzyć co do
                                    metra, wiadomo tylko że pozostaje w zasięgu danego nadajnika BTS.</p></S.TitleContainer>
                            <S.Phone MarginRight={"250px"} width={"600px"}
                                     alt={"Zdjęcie przedstawia telefon z funkcjami QuickContacts"} src={StealthModeMobile}/>
                        </S.PhoneContainer>
                    </Grow>
                </>
            </>
        )
    }
}

export default StealthMode;