import React from 'react';
import * as S from '../../../shared/style/index';
import {useMediaQuery} from "react-responsive";
import { useForm } from "react-hook-form";
import axios from "axios";

const CreateOrder = () => {
    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {
        axios.post('/mail', {
            to: [data.email],
            body: data.message
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});

    if (!isTabletOrMobile) {
        return (
            <>

                <S.Form onSubmit={handleSubmit(onSubmit)}>
                    <S.Row>
                        <S.InlineContainer>
                            <S.FormColumn>
                                <S.Label for={"name"}>Adres email</S.Label>
                                <S.OrderInput {...register("email")}/>
                            </S.FormColumn>
                        </S.InlineContainer>
                    </S.Row>
                    <S.Row>
                        <S.Label>Treść wiadomości</S.Label>
                        <S.Message {...register("message")} placeholderTextColor="white" placeholder={"Treść wiadomości..."}></S.Message>
                    </S.Row>
                    <S.Button MarginRight={"-15px"} MarginLeft={"auto"} width={"80px"}>Wyślij</S.Button>

                </S.Form>

            </>
        );
    } else {
        return (
            <>
                <S.Form onSubmit={handleSubmit(onSubmit)}>

                    <S.Label for={"name"}>Adres email</S.Label>
                    <S.OrderInput {...register("email")}/>



                    <S.Label>Treść wiadomości</S.Label>
                    <S.Message {...register("message")} placeholderTextColor="white" placeholder={"Treść wiadomości..."}></S.Message>

                    <S.Button MarginRight={"-15px"}  width={"80px"}>Wyślij</S.Button>

                </S.Form>
            </>
        )
    }
}

export default CreateOrder;