import * as S from '../../shared/style/index';
import {Link} from "react-router-dom";

const Breadcrumbs = (props) =>{
    if(props.site == 'main') {
        return (
            <>
                <Link style={{ textDecoration: 'none' }} to={props.site}><S.Breadcrumbs active>wszystkie aplikacje</S.Breadcrumbs></Link>
            </>
        );
    }else{
        return(
            <>
                <S.Breadcrumbs> <Link style={{ textDecoration: 'none', color: '#fff' }} to="/main">wszystkie aplikacje</Link> >> <S.Active>{props.site}</S.Active></S.Breadcrumbs>
            </>
        );
    }
}

export default Breadcrumbs;