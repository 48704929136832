import React from 'react';
import * as S from "../../shared/style/index";

const Footer = () =>{
    return(
      <>
        <S.Footer>

            <S.FooterText>Copyright @ 2021 k0der.</S.FooterText>

        </S.Footer>
      </>
    );
}

export default Footer;
