import Axios from "axios";
import React, {useState, useEffect, useSelector} from 'react';
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
    const [authenticated, setAuthentication] = useState(null);
    const [loadingComplete, setLoadingComplete] = useState(false);

    useEffect(
        () => {

            const isLogin = async () => {
                try {
                    const result = await Axios.post('/auth/refresh');

                    setAuthentication(result);
                } catch (e) {
                    console.log(e);
                }
                setLoadingComplete(true);
            }
            isLogin();
        },
        []
    );
    if(loadingComplete){
        return (
            <Route {...rest} render={props => (
                !!authenticated ?
                    <Component {...props} />
                    : <Redirect to="/logowanie" />
            )} />
        );
    }else{
        return ( <div> Loading... </div>);
    }
};

export default PrivateRoute;