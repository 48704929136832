import React from 'react';
import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import CryptoMessaging_EnterPin from "../../../shared/img/crypto_phones.png";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { useMediaQuery } from 'react-responsive';
import CryptoMessaging_Mobile from "../../../shared/img/crypto_phones_mobile.png";



const CryptoMessaging = () =>{
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    if(!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Breadcrumbs site="Crypto Messaging"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Crypto Messaging</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Miejsce gdzie rozmowę można prowadzić bezpiecznie używając wiadomości tekstowych, nagrań
                                głosowych, zdjęć i filmów.</p>
                            <p>Crypto messaging jest również szyfrowany AESem.</p></S.TitleContainer>
                        <S.Phone width={"900px"} MarginRight={"-20px"} alt={"Zdjęcie przedstawia telefon z funkcjami Crypto Messaing"}
                                 src={CryptoMessaging_EnterPin}/>

                    </S.PhoneContainer>
                </Grow>
            </>
        );
    }else{
        return(
            <>
                <Header/>
                <Breadcrumbs site="Crypto Messaging"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Crypto Messaging</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Miejsce gdzie rozmowę można prowadzić bezpiecznie używając wiadomości tekstowych, nagrań
                                głosowych, zdjęć i filmów.</p>
                            <p>Crypto messaging jest również szyfrowany AESem.</p></S.TitleContainer>
                        <S.Phone alt={"Zdjęcie przedstawia telefon z funkcjami Crypto Messaing"}
                                 src={CryptoMessaging_Mobile}/>

                    </S.PhoneContainer>
                </Grow>
            </>
        )
    }
}

export default CryptoMessaging;