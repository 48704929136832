import React from 'react';
import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import QuickContact from "../../../shared/img/quickContactsPC.png";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import {useMediaQuery} from "react-responsive";
import QuickContactMobile from "../../../shared/img/quickContactsPC_mobile.png";

const QuickContacts = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    if(!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Breadcrumbs site="Quick Contacts"/>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Quick Contacts</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Przycisk QUICK CONTACTS umożliwia dodanie kontaktu i nadanie mu unikatowego klucza
                                pole Key.</p>
                            <p>Sam ustalasz unikatowy klucz pomiędzy dodawanym kontaktem a Tobą.</p></S.TitleContainer>
                        <S.Phone MarginRight={"-20px"} width={"900px"} alt={"Zdjęcie przedstawia telefon z funkcjami QuickContacts"}
                                 src={QuickContact}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        );
    }else{
        return(
            <>
                <Header/>
                <Breadcrumbs site="Quick Contacts"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Quick Contacts</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Przycisk QUICK CONTACTS umożliwia dodanie kontaktu i nadanie mu unikatowego klucza
                                pole Key.</p>
                            <p>Sam ustalasz unikatowy klucz pomiędzy dodawanym kontaktem a Tobą.</p></S.TitleContainer>
                        <S.Phone width={"950px"} MarginRight={"150px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami QuickContacts"}
                                 src={QuickContactMobile}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        )
    }
}

export default QuickContacts;