import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import SecurePhoneImg from "../../../shared/img/securephone.png";
import React from "react";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import {useMediaQuery} from "react-responsive";
import SecurePhoneMobile from "../../../shared/img/telefonsecure_mobile.png";

const SecurePhone = () =>{
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    if(!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Breadcrumbs site="Secure Phone"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Secure Phone</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Pozwala na prowadzenie rozmowy po szyfrowanym torze audio. Ze względu na techniczne
                                ograniczenia, SECURE PHONE nie działa w ROAMINGU międzynarodowym.</p>
                            <p>W takich przypadkach należy używać aplikacji CRYPTO MESSAGING (gdzie wysłamy tekst,
                                głosówki, zdjęcia, filmy)</p>
                            <p>Wszystkie nasze rozwiązania dostarczają raporty o próbach nieuprawnionego logowania
                                widoczne jako logi w CODE BLACK.</p>
                        </S.TitleContainer>
                        <S.Phone width={"1050px"} MarginRight={"10px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami CodeRed"} src={SecurePhoneImg}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        )
    }else{
        return(
          <>
              <Header/>
              <Breadcrumbs site="Secure Phone"></Breadcrumbs>
              <Grow in={3300}>
                  <S.PhoneContainer>
                      <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Secure Phone</S.Title></S.TitleContainer>
                      <S.TitleContainer>
                          <p>Pozwala na prowadzenie rozmowy po szyfrowanym torze audio. Ze względu na techniczne
                              ograniczenia, SECURE PHONE nie działa w ROAMINGU międzynarodowym.</p>
                          <p>W takich przypadkach należy używać aplikacji CRYPTO MESSAGING (gdzie wysłamy tekst,
                              głosówki, zdjęcia, filmy)</p>
                          <p>Wszystkie nasze rozwiązania dostarczają raporty o próbach nieuprawnionego logowania
                              widoczne jako logi w CODE BLACK.</p>
                      </S.TitleContainer>
                      <S.Phone width={"600px"} MarginRight={"300px"}
                               alt={"Zdjęcie przedstawia telefon z funkcjami CodeRed"} src={SecurePhoneMobile}/>
                  </S.PhoneContainer>
              </Grow>
          </>
        );
    }
}

export default SecurePhone;

