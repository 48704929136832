import React from 'react';
import NetworkTypeImg from "../../../shared/img/network-type_phone.png";
import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
const NetworkType = () => {
    return(
        <>
            <Header/>
            <Breadcrumbs site="Network Type"></Breadcrumbs>
            <Grow in={3300}>
            <S.PhoneContainer>
                <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Network Type</S.Title></S.TitleContainer>
                <S.TitleContainer>
                    <p>Umożliwia szybki dostęp do funkcji wyboru typu sieci GSM, 4G i 3G, tylko 4G, tylko 3G.</p>
                   </S.TitleContainer>
                <S.Phone alt={"Zdjęcie przedstawia telefon z funkcjami Network Type"} src={NetworkTypeImg}/>
            </S.PhoneContainer>
            </Grow>
        </>
    );
}

export default NetworkType;