import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import CodeBlackImg from "../../../shared/img/codeblack_phone.png";
import React from "react";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import {useMediaQuery} from "react-responsive";
import CodeBlackMobile from "../../../shared/img/codeblack_mobile.png";

const CodeBlack = () => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});
    if (!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Breadcrumbs site="Code Black"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Code Black</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Funkcja umożliwiająca kasowanie pamięci flash telefonu. Uruchamiana z panelu programu lub
                                przy użyciu kombinacji klawiszy. Funkcja uruchamia jednocześnie procedurę SECURE_ERASE
                                wbudowaną w pamięć flash.</p>

                        </S.TitleContainer>
                        <S.Phone width={"600px"} MarginRight={"300px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami CodeRed"} src={CodeBlackImg}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        );
    } else {
        return (
            <>
                <Header/>
                <Breadcrumbs site="Code Black"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>Code Black</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Rejestr przychodzących zapytań o dane pozycjonujące telefonu.</p>
                            <p>Wszystkie nasze rozwiązania dostarczają raporty o próbach nieuprawnionego logowania
                                widoczne jako logi w CODE BLACK.</p>
                        </S.TitleContainer>
                        <S.Phone width={"600px"} MarginRight={"200px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami CodeRed"} src={CodeBlackMobile}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        )
    }
}

export default CodeBlack;