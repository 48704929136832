import React, {useEffect} from 'react';
import Header from "../Header/Header";
import * as S from "../../shared/style/index";
import CreateOrder from "../Forms/CreateOrder/CreateOrder";
import MainPhone from "../../shared/img/telefon_main_.png";
import {useHistory} from "react-router-dom";
import resize from "image-map-resizer";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import BtcImg from "../../shared/img/btc.png";
import EthImg from "../../shared/img/eth.png";

const Main = () => {

    let history = useHistory();
    resize();
    useEffect(() => {
        resize();
    }, []);


    const clickArea = (e) => {
        if (e.target.title == "callblocker")
            history.push("/callblocker");
        if (e.target.title == "codeblack")
            history.push("/codeblack");
        if (e.target.title == "codered")
            history.push("/codered");
        if (e.target.title == "cryptomessaging")
            history.push("/cryptomessaging");
        if (e.target.title == "imei")
            history.push("/imeichanger");
        if (e.target.title == "network")
            history.push("/networktype");
        if (e.target.title == "contacts")
            history.push("/quickcontacts");
        if (e.target.title == "securephone")
            history.push("/securephone");
        if (e.target.title == "stealth")
            history.push("/stealthmode");
        if (e.target.title == "settings")
            history.push("/systemsettings");
    }

    return (
        <>
            <Header/>
            <Breadcrumbs site="main">x</Breadcrumbs>
            <Grow in={3300}>
                <S.Container center>

                    <S.PhoneBox>
                        <S.Phone src={MainPhone} name="useMap" useMap="#Map" width="100%"/>

                        <map name="Map">
                            <area onClick={(e) => clickArea(e)} target="" alt="callblocker" title="callblocker" href=""
                                  coords="668,159,262,88"
                                  shape="rect"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="codeblack" title="codeblack" href=""
                                  coords="262,161,667,235"
                                  shape="0"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="codered" title="codered" href=""
                                  coords="262,236,667,303"
                                  shape="0"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="cryptomessaging" title="cryptomessaging"
                                  href=""
                                  coords="255,306,673,366" shape="0"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="imei" title="imei" href=""
                                  coords="259,369,671,437"
                                  shape="0"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="network" title="network" href=""
                                  coords="262,441,672,505" shape="0"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="contacts" title="contacts" href=""
                                  coords="263,509,675,577" shape="0"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="securephone" title="securephone" href=""
                                  coords="671,577,262,643" shape="0"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="stealth" title="stealth" href=""
                                  coords="263,646,670,715" shape="0"/>
                            <area onClick={(e) => clickArea(e)} target="" alt="settings" title="settings" href=""
                                  coords="263,716,674,792" shape="0"/>

                        </map>


                    </S.PhoneBox>
                    <CreateOrder/>


                    <S.LogoBox>
                     Akceptujemy płatność w BTC i ETH.
                        <S.BrandBox>
                            <S.LogoC src={BtcImg}>

                            </S.LogoC>
                            <S.LogoC src={EthImg}>
                            </S.LogoC>
                        </S.BrandBox>
                    </S.LogoBox>

                </S.Container>

            </Grow>

        </>
    );
}

export default Main;