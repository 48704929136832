import Homepage from "./components/Homepage/Homepage";
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Auth from "./components/Auth/Auth";
import Main from "./components/Main/Main";
import Instruction from "./components/Instruction/Instruction";
import QuickContacts from "./components/PhonePages/QuickContacts/QuickContacts";
import CryptoMessaging from "./components/PhonePages/CryptoMessaging/CryptoMessaging";
import NetworkType from "./components/PhonePages/NetworkType/NetworkType";
import StealthMode from "./components/PhonePages/StealthMode/StealthMode";
import ImeiChanger from "./components/PhonePages/ImeiChanger/ImeiChanger";
import CodeRed from "./components/PhonePages/CodeRed/CodeRed";
import CallBlocker from "./components/PhonePages/CallBlocker/CallBlocker";
import SystemSettings from "./components/PhonePages/SystemSettings/SystemSettings";
import SecurePhone from "./components/PhonePages/SecurePhone/SecurePhone";
import CodeBlack from "./components/PhonePages/CodeBlack/CodeBlack";
import PrivateRoute from "./components/Auth/PrivateRoute/PrivateRoute";
function App() {
  return (

    <Router>

          <Switch>
              <PrivateRoute exact path={'/main'} component={Main} />
              <PrivateRoute exact path={'/instrukcja'} component={Instruction}/>
              <PrivateRoute exact path='/quickcontacts' component={QuickContacts}/>
              <PrivateRoute exact path={"/cryptomessaging"} component={CryptoMessaging}/>
              <PrivateRoute exact path={"/networktype"} component={NetworkType}/>
              <PrivateRoute exact path={"/stealthmode"} component={StealthMode}/>
              <PrivateRoute exact path={"/imeichanger"} component={ImeiChanger}/>
              <PrivateRoute exact path={"/codered"} component={CodeRed}/>
              <PrivateRoute exact path={"/callblocker"} component={CallBlocker}/>
              <PrivateRoute exact path={"/systemsettings"} component={SystemSettings}/>
              <PrivateRoute exact path={"/securephone"} component={SecurePhone}/>
              <PrivateRoute exact path={"/codeblack"} component={CodeBlack}/>
              <Route exact path={'/'} component={Homepage} />
              <Route exact path={'/logowanie'} component={Auth} />

          </Switch>
      </Router>
  );
}

export default App;
