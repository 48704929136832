import React from 'react';
import Header from "../Header/Header";
import * as S from "../../shared/style/index";
import Grow from "@material-ui/core/Grow";

const Instruction = () => {
    return (
        <>
            <Header/>
            <Grow in={3300}>
            <S.Container>
                <S.TitleContainer>
                    <S.Title FontColor={"#00a8ff"}>Krótka instrukcja</S.Title>
                </S.TitleContainer>
                <S.SubTitle>Lista kroków po włączeniu telefonu (silikonowe etui na telefon jest w pudełku):</S.SubTitle>
            <S.BoxText>
                <p>1. Nie wsadzaj karty SIM jeszcze, wlacz telefon – domyslny PIN : 1111.</p>
                <p>2. Zmien numer IMEI.</p>
                <p>3. Wylacz telefon.</p>
                <p>4. Wsadz karte SIM.</p>
                <p>5. Ustaw swoj kod PIN (min 5 znakow), idz do :</p>
                <p>ustawienia systemowe – Zabezpieczenia – blokada ekranu PIN – 1111 -  wprowadz swoj kod PIN – potwierdz.</p></S.BoxText>

                <S.BoxText margin={"60px"}>
                    <S.SubHeader>Dodawanie kontaktow:</S.SubHeader>
                <p>1. Kontakty</p>
                <p>2. Zapytanie o PIN - nacisnij znak +</p>
                <p>3. W polu dodawanie kontaktu wprowadz:</p>
                <p>nazwę</p>
                <p>numer telefonu</p>
                <p>klucz (minimum 16 znakow, duze, male litery, cyfry, znaki specjalne)*</p>
                <p>*UWAGA:</p>
                <p>Ty i Twój dodawany kontakt musicie miec wpisane takie same klucze. Z kazdym kontaktem (numerem w ksiazce telefonicznej) ustaw inny/indywidualny klucz.</p>
                </S.BoxText>

                <S.BoxText margin={"60px"}>
                    <S.SubHeader>Autokasowanie wiadomosci:</S.SubHeader>
                    <p>1. Wiadomosci.</p>
                    <p>2. Zapytanie o  PIN – (prawy gorny rog , 3 kropki)  -  ustawienia – Autokasowanie – (wybierz dowolny przedizal czasowy)</p>
                </S.BoxText>

                <S.BoxText margin={"60px"}>
                    <S.SubHeader>Typ sieci GSM:  ustaw LTE (4G) i 3G</S.SubHeader>
                    <p>1. Ustawienia systemowe – siec i iinternet – siec komorkowa</p>
                    <p>Polaczeni a 4G - wylacz</p>
                    <p>Romaing – wlacz</p>
                <p>Mobilna transmisja danych - wlacz</p>
                </S.BoxText>

                <S.BoxText margin={"60px"}>
                    <S.SubHeader>Tryb ukrywania:</S.SubHeader>
                    <p>3G -measurment reports – OFF</p>
                    <p>4G – measurment reports – OFF</p>
                    <p>Positioning request notification – ON</p>
                </S.BoxText>

                <S.BoxText margin={"20px"}>(nie rozmawiaj z nieszyfrowanymi numerami telefonu , nie dajesz wtedy probki swojego glosu dla BETHOOVENa)</S.BoxText>

                <S.BoxText margin={"80px"}>Pobierz instrukcję w .pdf</S.BoxText>
            </S.Container>
            </Grow>
        </>
    );
}

export default Instruction;