import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import SystemSettingsImg from "../../../shared/img/systemsettings_phone.png";
import React from "react";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import {useMediaQuery} from "react-responsive";
import SystemSettingsMobile from "../../../shared/img/settings_mobile.png";

const SystemSettings = () =>{
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    if(!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Breadcrumbs site="System Settings"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>System Settings</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Możliwość zmiany ostawień systemowych.</p>
                            <p>Wszystkie nasze rozwiązania dostarczają raporty o próbach nieuprawnionego logowania
                                widoczne jako logi w CODE BLACK.</p>
                        </S.TitleContainer>
                        <S.Phone width={"600px"} MarginRight={"300px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami CodeRed"} src={SystemSettingsImg}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        )
    }else{
        return(
            <>
                <Header/>
                <Breadcrumbs site="System Settings"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>System Settings</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>Możliwość zmiany ostawień systemowych.</p>
                            <p>Wszystkie nasze rozwiązania dostarczają raporty o próbach nieuprawnionego logowania
                                widoczne jako logi w CODE BLACK.</p>
                        </S.TitleContainer>
                        <S.Phone width={"600px"} MarginRight={"300px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami CodeRed"} src={SystemSettingsMobile}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        )
    }
}

export default SystemSettings;