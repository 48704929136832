import Header from "../../Header/Header";
import * as S from "../../../shared/style";
import ImeiChangerImg from "../../../shared/img/imeichanger.png";
import ImeiChangerMobile from "../../../shared/img/imei_changeMobile.png";

import React from "react";
import Grow from "@material-ui/core/Grow";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import {useMediaQuery} from "react-responsive";

const ImeiChanger = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    if(!isTabletOrMobile) {
        return (
            <>
                <Header/>
                <Breadcrumbs site="IMEI Changer"></Breadcrumbs>
                <Grow in={3300}>
                    <S.PhoneContainer>
                        <S.TitleContainer><S.Title FontColor={"#00a8ff"}>IMEI Changer</S.Title></S.TitleContainer>
                        <S.TitleContainer>
                            <p>To zmienny numer IMEI, generowany losowo na żądanie użytkownika.</p>
                            <p>Nowy numer IMEI ma zachowaną strukturę opisaną i dostępną w dokumentacji 3GPP.</p>
                        </S.TitleContainer>
                        <S.Phone width={"600px"} MarginRight={"330px"}
                                 alt={"Zdjęcie przedstawia telefon z funkcjami QuickContacts"} src={ImeiChangerImg}/>
                    </S.PhoneContainer>
                </Grow>
            </>
        )
    }else{
        return(
        <>
            <Header/>
            <Breadcrumbs site="IMEI Changer"></Breadcrumbs>
            <Grow in={3300}>
                <S.PhoneContainer>
                    <S.TitleContainer><S.Title FontColor={"#00a8ff"}>IMEI Changer</S.Title></S.TitleContainer>
                    <S.TitleContainer>
                        <p>To zmienny numer IMEI, generowany losowo na żądanie użytkownika.</p>
                        <p>Nowy numer IMEI ma zachowaną strukturę opisaną i dostępną w dokumentacji 3GPP.</p>
                    </S.TitleContainer>
                    <S.Phone width={"600px"} MarginRight={"330px"}
                             alt={"Zdjęcie przedstawia telefon z funkcjami QuickContacts"} src={ImeiChangerMobile}/>
                </S.PhoneContainer>
            </Grow>
        </>
        )
    }
}
export default ImeiChanger;