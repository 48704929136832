import styled from 'styled-components';
import {Link} from "react-router-dom";

export const Header = styled.header`
  display: flex;
  height: 160px;
  background-color: #2F2F2F;
  align-items: center;
  margin-bottom: -25px;

`
export const Logo = styled.img`
  height: 77px;
  width: 166px;
  margin-left: ${props => props.margin ? props.margin : "150px"};
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`
export const Container = styled.div`
  background-color: #2F2F2F;
  margin-left: 150px;
  display: flex;
  align-items: ${props => props.center ? "center" : "unset"};;
  flex-direction: ${props => props.direction ? props.direction : "column"};
  margin-right: 150px;
  @media (max-width: 600px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2F2F2F;
  margin-left: 150px;
  margin-right: 150px;
  @media (max-width: 600px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const Title = styled.h1`
  font-family: 'Roboto', sans-serif;

  font-size: 25px;
  color: ${props => props.FontColor ? props.FontColor : "#fff"};
  @media (max-width: 600px) {
    font-size: 20px;
  }
`

export const Box = styled.div`
  margin-top: 60px;
  margin-bottom: 25px;
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`

export const BoxText = styled.p`
  color: ${props => props.color ? props.color : "#fff"};
  padding: 1px;
  margin: 0;
  margin-top: ${props => props.margin ? props.margin : "0"};

  p {
    padding: 0;
    margin: 0;
  }
`

export const BoxTitle = styled.h2`
  color: #fff;
  font-size: 40px;
  @media (max-width: 600px) {
    font-size: 30px;
  }
`

export const Button = styled.button`
  padding: 15px;
  width: ${props => props.width ? props.width : "255px"};
  background-color: ${props => props.background_color ? props.background_color : "#00A8FF"};
  border: ${props => props.background_color ? "1px solid #fff" : "none"};
  border-radius: 5px;
  margin-left: ${props => props.MarginLeft ? props.MarginLeft : "unset"};
  color: #fff;
  margin-top: ${props => props.top ? props.top : "15px"};
  margin-right: ${props => props.MarginRight ? props.MarginRright : "15px"};
  text-transform: uppercase;
  @media (max-width: 600px) {
    width: ${props => props.width ? props.width : "250px"};
    margin-top: 10px;
    margin-bottom: 10px;
    margin-bottom: 10px;
  }
`
export const Footer = styled.footer`
  background-color: #2A2A2A;
  min-height: 10px;
  color: #fff;
  font-size: 11px;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
`

export const FooterText = styled.p`
  margin-left: 150px;
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`

export const AuthBox = styled.form`
  display: flex;
  color: #fff;
  flex-direction: column;

`

export const AuthText = styled.p`
  font-size: 20px;
  @media (max-width: 600px) {
    text-align: center;
  }
`

export const Input = styled.input`
  width: 97%;
  height: 40px;
  border-radius: 10px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #fff;
  padding: 5px;
  margin-top: 10px;

`

export const Label = styled.label`
  padding: 5px;
  color: ${props => props.color ? props.color : "#fff"};
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 14px;
`
export const AuthLogo = styled.img`
  height: 77px;
  width: 166px;
  margin-top: 40px;
  margin-bottom: 40px;


  margin-left: ${props => props.margin ? props.margin : "150px"};
  @media (max-width: 600px) {
    margin-left: 10px;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InputContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`


export const Column = styled.div`
  width: ${props => props.width ? props.width : "40%"};
  height: 100%;
  margin-top: ${props => props.MarginTop ? props.MarginTop : "unset"};
  margin-left: ${props => props.MarginLeft ? props.MarginLeft : "unset"};
  margin-bottom: ${props => props.MarginBottom ? props.MarginBottom : "unset"};
  display: ${props => props.center ? "flex" : "unset"};
  justify-content: ${props => props.center ? "center" : "unset"};
  align-items: ${props => props.center ? "center" : "unset"};

  video {
    width: 70%;
    height: 80%;
    border: none;
    z-index: 0;
    outline: none;
    line-height: 0;
  }

`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 650px;
  flex-wrap: nowrap;
  @media (max-width: 600px) {

    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
`


export const Row = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    width: 70% !important;
  }
`

export const InlineContainer = styled.div`
  display: flex;
  color: #fff;
  font-size: ${props => props.fontSize ? props.fontSize : "14px"};
`

export const OrderInput = styled.input`
  height: 40px;
  border-radius: 10px;
  width: ${props => props.width ? props.width : "100"};
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #fff;
  margin-left: 6px;
  @media (max-width: 600px) {
    width: 98% !important;
  }
`

export const Message = styled.textarea`
  width: 97.9%;
  min-height: 250px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #fff;
  margin-left: 6px;
  color: #fff;
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  @media (max-width: 600px) {
    width: 97% !important;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #fff;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #fff;
  }
`

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Checkbox = styled.input`
  margin-bottom: auto;
  margin-top: 4px;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;

  p {
    margin: 0;
    text-align: center;

    @media (max-width: 600px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }

  }
`

export const SubTitle = styled.h3`
  color: #fff;
  font-weight: 600;
  font-size: 17px;
`

export const SubHeader = styled.h3`
  font-weight: 600;
  margin: 0;
  padding: 0;
  font-size: 17px;
`

export const Phone = styled.img`
  width: ${props => props.width ? props.width : "450px"};
  margin-right: ${props => props.MarginRight ? props.MarginRight : "170px"};
  padding: 20px;
  position: relative;
  @media (max-width: 600px) {
    width: 90% !important;
    margin-right: 15px;
  }
`

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Icon = styled.img`
  position: relative;
  margin-left: -450px;
  margin-top: -190px;


`

export const PhoneBox = styled.div`
  position: relative;
  width: 40%;
  margin-right: 150px;

  @media (max-width: 1900px) {
    width: 50%;
  }
  @media (max-width: 1300px) {
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-right: unset;
  }

`


export const Breadcrumbs = styled.div`
  color: ${props => props.active ? "#00a8ff" : "#fff"};;
  margin-left: 150px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-left: 10px;
    font-size: 12px;
  }


`
export const Active = styled.p`
  color: #00a8ff;
  margin: 0;
  margin-top: 2px;
  margin-left: 5px;
`

export const NavRight = styled.p`
  margin-left: auto;
  margin-right: 15%;
  color: #fff;

  a {
    padding: 5px;
    color: #fff;
    text-decoration: unset;
    cursor: pointer;
  }
`
export const LinkStyled = styled.a`
  z-index: 99999;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  margin-right: ${props => props.Margin ? "15%" : "unset"};
`;

export const Right = styled.div`
  margin-left: auto;
`
export const Left = styled.div`
  margin-right: 15%;
`
export const LogoC = styled.img`
  height: 50px;
  padding: 5px;
`

export const LogoBox = styled.div`
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

`

export const BrandBox = styled.div`
  padding: 5px;
`

export const mailLink = styled.a`
color: #00A8FF !important;
  text-decoration: none;
`